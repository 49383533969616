<template>
  <div class="wallet-section mt-14 max-width">
    <v-row>
      <v-col cols="12" sm="6" md="6" v-for="(item, i) in wallets" :key="i">
        <v-card
          class="d-flex align-end pa-4"
          style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09) !important"
        >
          <img
            class="mx-5"
            width="37"
            height="35"
            :src="require('@/assets/images/profile/wallet_tapl.png')"
            alt=""
          />
          <div>
            <span class="mx-2">{{ i == 0 ? $t('in') : $t('out') }}</span>

            <img
              :src="
                require(`@/assets/images/icons/${
                  i == 0 ? 'arrow_dawn' : 'arrow_top'
                }.png`)
              "
              width="10"
              height="10"
              alt=""
            />
            <span class="font-25 black--text font-600 d-block"
              >{{ $t("total") }}
              <span class="primary--text font-30" style="font-family: bold">{{
               i==0 ? wallets[0].value : wallets[1].value
              }}</span>
              <span>USD</span>
            </span>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <base-input
          :placeholder="`${$t('searsh')}`"
          icon="mdi-magnify"
          :rules="[]"
          @input="filterHandler"
        ></base-input>
      </v-col>
    </v-row>

    <!-- <v-row align="center" class="text-center">
      <v-col md="3" class="text-left">
        <span class="font-20 font-300 black--text">{{ $t("order") }}</span>
      </v-col>
      <v-col md="2">
        <img
          class="mr-1"
          width="22"
          height="22"
          :src="require('@/assets/images/profile/arrow-green.png')"
          alt=""
        />
        <span class="font-20 font-300 black--text">{{ $t("totalIn") }}</span>
      </v-col>
      <v-col md="2">
        <img
          class="mr-1"
          width="22"
          height="22"
          :src="require('@/assets/images/profile/arrow-red.png')"
          alt=""
        />
        <span class="font-20 font-300 black--text">{{ $t("totalOut") }}</span>
      </v-col>
      <v-col md="2">
        <span class="font-20 font-300 black--text">{{ $t("date") }}</span>
      </v-col>
      <v-col md="2">
        <span class="font-20 font-300 black--text">{{ $t("details") }}</span>
      </v-col>
    </v-row> -->
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.orderName="{ item }">
        <v-avatar size="30">
          <v-img :src="item.image" alt="John" />
        </v-avatar>
        <span class="font-20 font-300 mx-3 black--text">{{
          item.orderName
        }}</span>
      </template>

      <template v-slot:item.Details="{ item }">
        <v-btn color="primary" v-if="item.orderName">
          <img
            :src="require('@/assets/images/icons/add.png')"
            class="c-btn px-1"
            elevation="0"
            height="10"
            @click="showDetails(item)"
            alt=""
          />
        </v-btn>
      </template>
    </v-data-table>
    <div class="d-flex justify-end">
      <v-pagination
        v-if="totalPages >= 1"
        v-model="page"
        :length="totalPages"
        color="primary"
      ></v-pagination>
    </div>

    <!-- wallet dialog -->
    <v-dialog
      v-model="walletDialog"
      max-width="450"
      content-class="white over-scroll"
    >
      <wallet-details-dialog
        :key="walletDialog"
        @close="walletDialog = false"
        :item="selectedItem"
      ></wallet-details-dialog>
    </v-dialog>
  </div>
</template>

<script>
import WalletDetailsDialog from "@/components/dialogs/WalletDetails";
export default {
  components: {
    WalletDetailsDialog,
  },
  data: (vm) => ({
    walletDialog: false,
    wallets: [{}, {}],
    items: [],
    itemsCopy: [],
    page: 1,
    totalPages: 1,
    selectedItem: "",
    headers: [
      { text: vm.$t('orderName'), value: "orderName" },
      { text: vm.$t('totalIn'), value: "in" },
      { text:  vm.$t('totalOut'), value: "out" },
      { text:  vm.$t('totalIn'), value: "shipment_date" },
      { text: vm.$t("details"), value: "Details" },
    ],
  }),
  methods: {
    showDetails(item) {
      this.walletDialog = true;
      this.selectedItem = item;
    },
    filterHandler(event) {
      this.items = [
        ...this.itemsCopy.filter((item) => item.orderName.indexOf(event) > -1),
      ];
    },
    async getData() {
      let { data } = await this.$axios.get("/getMyWallet");
      this.wallets[0].value = data.data.total_in;
      this.wallets[1].value = data.data.total_out;

      // copy the items for filter
      this.itemsCopy = data.data.orders;
      this.items = data.data.orders;
      this.totalPages = this.items.length / 10;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.wallet-section {
  .v-input__prepend-inner {
    margin-top: 12px !important;
  }
  .order-circle {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
</style>
