<template>
  <div class="wallet-dialog py-10 px-3">
    <!-- img -->
    <div class="text-center">
      <div
        class="mx-auto d-inline-block radius-15 graybg-bg  px-6 py-2 "
      >
        <img
          class=""
          width="170"
          height="140"
          :src="item.image"
          alt=""
        />
      </div>
    </div>
    <div class="text-center mt-3 mb-4">
      <!-- name -->
      <div class="d-flex align-center justify-center">
        <span class="font-25 black--text d-block mr-1">{{$t('orderName')}}:</span>
        <span class="font-25 black--text font-300">{{item.orderName}}</span>
      </div>

      <!-- no number -->
      <div class="d-flex align-center justify-center">
        <span class="font-25 black--text d-block mr-1">{{$t('order_number')}}:</span>
        <span class="font-25 black--text font-300">{{item.order_number}}</span>
      </div>
    </div>

    <div
      class="radius-15 mb-3 graybg-bg px-3 py-2 d-flex align-center justify-space-between"
      v-for="(transaction, i) in item.tansactions"
      :key="i"
    >
      <div class="d-flex align-center">
        <img
        v-if="transaction.type == 'in'"
          class="mr-3"
          :src="require(`@/assets/images/profile/arrow-green.png`)"
          alt=""
        />
        <img
        v-if="transaction.type != 'in'"
          class="mr-3"
          :src="require(`@/assets/images/profile/arrow-green.png`)"
          alt=""
        />
        <div>
          <span class="font-20 black--text">{{transaction.reason}}</span>
          <span class="font-18 black--text d-block">{{transaction.created_at}}</span>
        </div>
      </div>

      <span class="green--text font-20 font-500">${{transaction.ammount}}</span>
    </div>

    <div class="text-center mt-4">
      <v-btn
        class="c-btn"
        color="primary"
        min-width="240"
        height="40"
        @click="$emit('close')"
        ><span class="white--text font-300 font-20">{{$t('close')}}</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props:{
    item:{
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style></style>
